/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react"
import toastService from "../../toast";
import { invoiceList } from "../../api";
import { Link } from "react-router-dom";

export default function ActivateInvoices({ setNav, setLoading, setPayLater }){
    const [data,setData] = useState([]);
    
    const loadData = async() => {
        try{
            setLoading(true);
            let response = await invoiceList();
            setLoading(false);
            if(response.data && response.data.status === 'success'){
                setData(response.data.data);
            }else{
                toastService.error();
            }
        }catch(err){
            setLoading(false)
            console.log({ err });
            toastService.error();
        }
    }

    useEffect(()=>{
        setNav(true);
        loadData();
    }, []);

    return(
        <section className="invoices" >
            <h1>INVOICES</h1>
            <Link to='/activate/features' className="invoices__container__back" >
                <img src="/assets/back.png" alt="back" />
                Back
            </Link>
            <div className="invoices__container" >
                <h5>Current Invoice</h5>
                <table>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Brand</th>
                        <th>Action</th>
                    </tr>
                    {
                        data.map((e,i)=>(
                            (i === 0) ?
                            (<tr>
                                <td>
                                    <Link to={`/activate/invoice/${e.fileKey}`} onClick={()=>setPayLater(true)} >
                                        {new Date(e.createdOn).toLocaleDateString()}
                                    </Link>
                                </td>
                                <td>
                                    ₹{e.invoiceAmount}
                                </td>
                                <td>
                                    {e.brand}
                                </td>
                                <td>
                                    <Link className="invoices__container__tbtn" onClick={()=>setPayLater(true)} 
                                        to={`/activate/invoice/${e.fileKey}`}  >
                                        Claim Discount
                                    </Link>
                                </td>
                            </tr>) 
                            : null
                        ))
                    }
                </table>
                {
                    data.length > 1 &&
                (<>
                <div className="invoices__container__divider" ></div>
                <h5>Previous Invoices</h5>
                <table>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Brand</th>
                        <th>Action</th>
                    </tr>
                    {
                        data.map((e,i)=>(
                            (i !== 0) ?
                            (<tr>
                                <td>
                                    <Link to={`/activate/invoice/${e.fileKey}`} onClick={() => setPayLater(false)} >
                                        {new Date(e.createdOn).toLocaleDateString()}
                                    </Link>
                                </td>
                                <td>
                                    ₹{e.invoiceAmount}
                                </td>
                                <td>
                                    {e.brand}
                                </td>
                                <td>
                                    <Link to={`/activate/invoice/${e.fileKey}`} onClick={() => setPayLater(false)}  >
                                        View Details
                                    </Link>
                                </td>
                            </tr>) 
                            : null
                        ))
                    }
                </table>
                </>)
                }
            </div>
        </section>
    )
}