const CONSTANTS = {
    apiUrl: 'https://api.quidcash.in/api/v1',
    // apiUrl: 'http://localhost:7700/api/v1',
    authCookie: 'DftKhHnFNZHqSATfkpgDhKlTPtefhynLaIsSmgYDufycYKxcLP',
    user: 'QuID_user',
    number: 'wJAFRcyTRbFt',
    distributor: 'cauvery',
    user_id: 'hTrNavePhVyjdCIIxmwCuKLSmodTyJQOyKdsHojDNnWgeJCare'
};

export default CONSTANTS;

