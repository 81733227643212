import { Link, useParams } from "react-router-dom";
import { getInvoiceAmount } from "../../api";
import toastService from "../../toast";
import { useEffect, useState } from "react";

export default function Paylater(){
    const params = useParams();
    const [invoiceAmount, setInvoiceAmount] = useState(0);

    const loadData = async() => {
        try{
            let response = await getInvoiceAmount(params.id);
            if(response && response.data.status === 'success'){
                setInvoiceAmount(response?.data?.data?.invoiceAmount || 0)
            }else{
                toastService.error();
            }
        }catch(err){
            console.log(err)
            toastService.error();
        }
    }

    useEffect(() => {
        window.scrollTo(0,0)
        loadData()
    }, [])

    return(
        <section className="paylater" >
            <Link to='/loan/invoices' className="paylater__container__back" >
                <img src="/assets/back.png" alt="back" />
                Back
            </Link>
            <div className="paylater__container" >
                <div className="paylater__container__content" >
                    <p>New extended due date</p>
                    <p>10 Jan 2023</p>
                </div>
                <div className="paylater__container__content" >
                    <p>Cauvery Invoice Amount</p>
                    <p>₹ {invoiceAmount.toLocaleString('en-IN')}</p>
                </div>
                <div className="paylater__container__content" >
                    <p>Amount Payable</p>
                    <p>₹ {invoiceAmount.toLocaleString('en-IN')}</p>
                </div>
                <div className="paylater__container__content" >
                    <p>QuID Coins Reward</p>
                    <p><img src="/assets/loan/quid-coin.png" alt="coins" /> {invoiceAmount.toLocaleString('en-IN')}</p>
                </div>
                <Link to={`/loan/success/${params.id}`} className="paylater__container__btn btn btn__yellow" >Paylater</Link>
            </div>
        </section>
    )
}