import { Link, useParams } from "react-router-dom"
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { getSession } from "../../store";
import CONSTANTS from "../../constants";
import { useEffect, useState } from "react";
import toastService from "../../toast";
import { finzyEsign, getInvoiceAmount } from "../../api";

pdfjs.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js';

export default function InvoiceDetails({ setNav, payLater }){
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [invoiceAmount, setInvoiceAmount] = useState(0);
    const [paylater, setPaylater] = useState(false)

    const params = useParams();

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const requestHeaders = {
        Authorization: getSession(CONSTANTS.authCookie)
    };

    const onNextClick = () => {
        if(currentPage < numPages){
            setCurrentPage( state=>state + 1 )
        }
    };

    const onPreviousClick = () => {
        if(currentPage > 1){
            setCurrentPage( state => state - 1 )
        }
    }

    const loadData = async() => {
        try{
            let response = await getInvoiceAmount(params.key);
            if(response && response.data.status === 'success'){
                setInvoiceAmount(response.data.data.invoiceAmount);
                setPaylater(response?.data?.paylater)
            }else{
                toastService.error();
            }
        }catch(err){
            console.log(err)
            toastService.error();
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        setNav(true);
        loadData();
    }, [])

    return(
        <section className="invoice" >
            <div className="invoice__container" >
                <Link to='/loan/invoices' className="invoice__container__back" >
                    <img src="/assets/back.png" alt="back" />
                    Back
                </Link>
                <div className="invoice__container__file" >
                    <Document 
                        className={"PDFDocument"} 
                        file={{
                            url: CONSTANTS.apiUrl + '/retailer/invoice/detail/' + params.key,
                            httpHeaders: requestHeaders,
                        }} 
                        onLoadSuccess={onDocumentLoadSuccess}>  
                        <Page
                            className={"PDFPage PDFPageOne"} 
                            renderTextLayer={false} renderInteractiveForms={false}
                            key={`page`} pageNumber={currentPage}  />
                    </Document>
                    {
                        (numPages > 1) &&
                        (<div className="page-controls">
                            <button type="button" onClick={onPreviousClick} >{'<'}</button>
                                <span>{currentPage} of {numPages}</span>
                            <button type="button" onClick={onNextClick} >{'>'}</button>
                        </div>)
                    }
                </div>
                {
                    payLater && (
                        <h5>Unlock <img src="/assets/loan/quid-coin.png" alt="coins" /> {invoiceAmount.toLocaleString('en-IN')} QuID reward points by opting to pay the invoice later</h5>
                    )
                }
                <div className="invoice__container__btn" >
                    {
                        payLater && (
                            <Link 
                                to={(!paylater) 
                                    ? `/loan/finzy/${params.key}`
                                    : `/loan/paylater/${params.key}` } className='btn btn__yellow' >Pay Later</Link>
                        )
                    }
                </div>
            </div>
        </section>
    )
};