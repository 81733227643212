import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Welcome from './Welcome';
import { useState } from 'react';
import Apply from './Apply';
import Protected from './Protected';
import Loading from './Loading';
import Loan from './Loan';
import Profile from './Loan/profile';
import Invoices from './Loan/invoices';
import InvoiceDetails from './Loan/invoice';
import Success from './Loan/success';
import Repayment from './Loan/repayment';

import Features from './Activate/features';
import Activate from './Activate';
import ActivateThankYou from './Activate/thank-you';
import ActivateInvoices from './Activate/invoices';
import ActivateInvoiceDetails from './Activate/invoice';
import Finzy from './Loan/finzy';
import Paylater from './Loan/paylater';

export default function App(){
    const [nav, setNav] = useState(true);
    const [loading, setLoading] = useState(false);
    const [payLater, setPayLater] = useState(true);
    return(
        <main>
            <BrowserRouter>
                <Navbar nav={nav} />
                <Routes>
                    <Route path='/login' element={ <Welcome setNav={setNav} setLoading={setLoading} />} >
                    </Route>    
                    <Route path='/apply/:id' element={
                        <Protected>
                            <Apply setNav={setNav} setLoading={setLoading} />
                        </Protected>}
                    ></Route>
                    <Route path='/loan' element={
                        <Protected>
                            <Loan setNav={setNav} setLoading={setLoading} />
                        </Protected>
                    } ></Route>
                    <Route path='/loan/profile' element={
                        <Protected>
                            <Profile setNav={setNav} setLoading={setLoading} />
                        </Protected>
                    } ></Route>
                    <Route path='/loan/invoices' element={
                        <Protected>
                            <Invoices setNav={setNav} setLoading={setLoading} setPayLater={setPayLater} />
                        </Protected>
                    } ></Route>
                    <Route path='/loan/repayment' element={
                        <Protected>
                            <Repayment setNav={setNav} setLoading={setLoading} setPayLater={setPayLater} />
                        </Protected>
                    } ></Route>
                    <Route path='/loan/invoices/:key' element={
                        <Protected>
                            <InvoiceDetails setNav={setNav} setLoading={setLoading} payLater={payLater}  />
                        </Protected>
                    } ></Route>
                     <Route path='/loan/success/:key' element={
                        <Protected>
                            <Success setNav={setNav} setLoading={setLoading} />
                        </Protected>
                    } ></Route>
                    <Route path='/loan/features' element={
                        <Protected>
                            <Features setNav={setNav} setLoading={setLoading} />
                        </Protected>
                    } ></Route>
                    <Route path='/loan/finzy/:key' element={
                        <Protected>
                            <Finzy setNav={setNav} setLoading={setLoading} />
                        </Protected>
                    } />
                    <Route path='/loan/paylater/:id' element={
                        <Protected>
                            <Paylater setNav={setNav} setLoading={setLoading} />
                        </Protected>
                    } />
                    <Route path='/activate' element={
                        <Protected>
                            <Activate setNav={setNav} setLoading={setLoading} />
                        </Protected>
                    } ></Route>
                    <Route path='/activate/features' element={
                        <Protected>
                            <Features setNav={setNav} setLoading={setLoading} />
                        </Protected>
                    } ></Route>
                    <Route path='/activate/invoices' element={
                        <Protected>
                            <ActivateInvoices setNav={setNav} setLoading={setLoading} setPayLater={setPayLater} />
                        </Protected>
                    } ></Route>
                    <Route path='/activate/invoice/:key' element={
                        <Protected>
                            <ActivateInvoiceDetails setNav={setNav} setLoading={setLoading} payLater={payLater}  />
                        </Protected>
                    } ></Route>
                    <Route path='/activate/thank-you' element={
                        <Protected>
                            <ActivateThankYou setNav={setNav} setLoading={setLoading} />
                        </Protected>
                    } ></Route>

                    <Route path='*' element={<Welcome setNav={setNav} setLoading={setLoading} />} ></Route>
                </Routes>
                <ToastContainer
                    theme="colored"
                    position="bottom-right"
                />
                <Loading loading={loading} />
            </BrowserRouter>
        </main>
    )
}
