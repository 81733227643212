import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Features({ setNav }){

    useEffect(()=>{
        window.scrollTo(0,0);
        setNav(true);
    }, []);

    return (
        <section className="features" >
            <div className="features__card" >
                <div className="features__card__txt" >
                    <h5>Available Credit Balance</h5>
                    <h6>₹ 2,00,000</h6>
                </div>
            </div>
            <div className="features__points" >
                <div className="features__points__card" >
                    <img src="/assets/activate/interest.png" alt="interest" />
                    <p>30 days 0% interest credit</p>
                </div>
                <div className="features__points__card" >
                    <img src="/assets/activate/discount.png" alt="discount" />
                    <p>Rs 100 cash discount</p>
                </div>
                <div className="features__points__card" >
                    <img src="/assets/activate/reward.png" alt="reward" />
                    <p>1000 reward points</p>
                </div>
                <div className="features__points__card" >
                    <img src="/assets/activate/cashback.png" alt="cashback" />
                    <p>Cash back</p>
                </div>
                <Link to={'/activate/invoices'} className="btn btn__yellow" >Claim Discount & Pay Later</Link>
            </div>
        </section>
    )
}