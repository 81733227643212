import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Compressor from 'compressorjs';

import toastService from '../../toast';
import { findData, singleFile, updateData } from "../../api";

export default function Apply({ setNav, setLoading }){
    const params = useParams();

    const [index, setIndex] = useState(0);
    const [name, setName] = useState('');
    const [GST, setGST] = useState('');
    const [storeName,setStoreName] = useState('');
    const [PAN, setPAN] = useState('');
    const [aadhar, setAadhar] = useState('');
    const [alternateNumber, setAlternateNumber] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState(null); 

    const [PANDoc, setPANDoc] = useState(null);
    const [aadharDoc, setAadharDoc] = useState(null);
    const [aadharBackDoc, setAadharBackDoc] = useState(null);
    const [GSTDoc, setGSTDoc] = useState(null);
    const [bankDoc, setBankDoc] = useState(null);
    const [storePic, setStorePic] = useState(null);
    const [selfie, setSelfie] = useState(null);
    const [cheque, setCheque] = useState(null);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    const [prev, setPrev] = useState(null);

    const loadData = async () => {
        try{
            let response = await findData(params['id']);
            if(response && response.status === 200){
                let data = response.data.data;
                setName(data.name);
                setGST(data.GST);
                setStoreName(data.storeName);
                setPAN(data.PAN);
                setAadhar(data.aadhar);
                setAlternateNumber(data.alternateNumber);
                setPrev(data);
                setEmail(data.email);
                if(data.dob){
                    const dobDate = new Date(data.dob);
                    setDob(dobDate.toISOString().split('T')[0]); 
                }
            }
        }catch(err){
            toastService.error();
        }
    }


    useEffect(()=>{
        window.scrollTo(0,0);
        loadData();

        if ("geolocation" in navigator) {
            console.log("Available");
          } else {
            console.log("Not Available");
          }
        setNav(true);
    }, []);

    const getLocation = () => {
        navigator.geolocation.getCurrentPosition(function(position) {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          });
    };

    const compressImage = (_file) => {
        return new Promise((resolve, reject)=>{
            const image = _file;
            new Compressor(image, {
                quality: 0.3, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedResult) => {      
                    resolve(compressedResult)
                },
                error: (err) => {
                    reject(err);
                }
              });
        })
    };

    const singleFileUpload = async(_key, _file) => {
        try{
            let file = _file
            const formData = new FormData();
            if(_file.type.includes("image/")){
                file = await compressImage(file)
                console.log({ file, _file })
                formData.append(_key, file, _file.name);
            }else{
                formData.append(_key, file);
            }
            // return
            setLoading(true);
            let response = await singleFile(params.id, formData);
            setLoading(false);
            if(response && response.status === 200){
                console.log('FILE UPLOAD');
            }else{
                toastService.error();
            }
        }catch(err){
            setLoading(false);
            console.log({ err });
            toastService.error();
        }
    }

    const submit = async(e) => {
        try{
            e.preventDefault();

            let files = { 
                PANDoc,
                aadharDoc,
                GSTDoc,
                bankDoc,
                storePic,
                selfie,
                aadharBackDoc,
                cheque
            };

            let _data = {
                name,
                GST,
                storeName,
                PAN,
                alternateNumber,
                aadhar,
                longitude,
                latitude,
                email,
                dob
            };

            if(_data.GST &&_data.GST.length !== 15){
                toastService.error('Enter valid 15 digit GST number');
                return;
            }

            if( _data.aadhar && _data.aadhar.length !== 12){
                toastService.error('Enter valid 12 digit Aadhar');
                return;
            }

            if(_data.alternateNumber && _data.alternateNumber.length !== 10){
                toastService.error('Enter 10 digit alternate Mobile number');
                return;
            }

            if(_data.PAN && _data.PAN.length !== 10){
                toastService.error('Enter 10 digit PAN number');
                return;
            }

            setLoading(true);
            let response = await updateData(params.id, _data);
            setLoading(false);
            // setTimeout(()=>{
            // }, 4000);
            window.scrollTo(0,0);
            if(response && response.status === 200){
                toastService.success('Uploaded successfull');
                setIndex(1);
            }else{
                toastService.error();
            }

        }catch(err){
            setLoading(false);
            console.log({ err });
            toastService.error();
        }
    }

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18);
    const minDateISOString = minDate.toISOString().split('T')[0];

    return(
        <div className='apply'>
            <div className='apply__page'>
                <div className={index === 0 ? 'apply__page__index apply__page__index--active' : 'apply__page__index'} onClick={()=>{setIndex(0)}}>
                    <img src={index === 0 ? '/assets/contact-white.png' : '/assets/contact.png'} alt="contact" />
                </div>
                <div className="apply__page__sep"></div>
                <div className={index === 1 ? 'apply__page__index apply__page__index--active' : 'apply__page__index'} >
                    <img src={index === 1 ? '/assets/congrats-white.png' : '/assets/congrats.png'} alt="celebration" />
                </div>
            </div>
            <div className='apply__card' >
            {
                index === 0 && 
                (
                    <form className='apply__card__form' onSubmit={submit} >
                        <div className='apply__card__form__container'>
                            
                            <div  className='apply__card__form__container__field'>
                                <label>Owner's Name</label>
                                <input type="text" name="name" placeholder="ex - Shreenivas Joshi" 
                                    value={name} required 
                                    onChange={e=>{setName(e.target.value)}} 
                                />
                            </div>
                            
                            <div  className='apply__card__form__container__field'>
                                <label>Owner's Date Of Birth (DOB)</label>
                                <input type="date" name="dob" 
                                    value={dob} max={minDateISOString} 
                                    onChange={e=>{setDob(e.target.value)}} 
                                />
                            </div>
                            
                            <div  className='apply__card__form__container__field'>
                                <label>GST Number</label>
                                <input type="text" name="GST" placeholder="ex - 18AABCU9603R1ZZ" 
                                    value={GST} 
                                    onChange={e=>{setGST(`${e.target.value}`.trim().toUpperCase())}} 
                                />
                            </div>

                            <div  className='apply__card__form__container__field'>
                                <label>Store Name</label>
                                <input type="text" name="Store" placeholder="ex - JOSHI AI STORES" 
                                    value={storeName} required
                                    onChange={e=>{setStoreName(e.target.value)}} 
                                />
                            </div>
                            
                            <div  className='apply__card__form__container__field'>
                                <label>PAN</label>
                                <input type="text" name="PAN" placeholder="ex - ABCTY1234D" 
                                    value={PAN} 
                                    onChange={e=>{setPAN(`${e.target.value}`.trim().toUpperCase())}} 
                                />
                            </div>

                            <div  className='apply__card__form__container__field'>
                                <label>Aadhar Number</label>
                                <input type="text" name="Aadhar" placeholder="ex - 499118665246" 
                                    value={aadhar}
                                    onChange={e=>{setAadhar(`${e.target.value}`.trim().toUpperCase())}} 
                                />
                            </div>

                            <div  className='apply__card__form__container__field'>
                                <label>Alternate Number</label>
                                <input type="text" name="Alternate Number" placeholder="ex - 9876543210" 
                                    value={alternateNumber}
                                    onChange={e=>{setAlternateNumber(e.target.value)}} 
                                />
                            </div>

                            <div  className='apply__card__form__container__field'>
                                <label>Email</label>
                                <input type="email" name="Email" placeholder="shreenivas@aistores.ai" 
                                    value={email}
                                    onChange={e=>{setEmail(e.target.value)}} 
                                />
                            </div>

                            <div className='apply__card__form__container__field apply__card__form__container__field__file' >
                                <label>Upload PAN Card  {(prev && prev['PANDoc']) && <>✅</>} </label>
                                <input type="file"  name="PAN DOC"
                                onChange={(e)=>singleFileUpload('PANDoc', e.target.files[0])}/>
                               
                            </div>
                        
                            <div className='apply__card__form__container__field apply__card__form__container__field__file' >
                                <label>Upload Aadhar Front Card {(prev && prev['aadharDoc']) && <>✅</>}  </label>
                                <input type="file"  name="Aadhar Doc"
                                onChange={(e)=>singleFileUpload('aadharDoc', e.target.files[0])}/>
                            </div>
                            
                            <div className='apply__card__form__container__field apply__card__form__container__field__file' >
                                <label>Upload Aadhar Back Card {(prev && prev['aadharBackDoc']) && <>✅</>}  </label>
                                <input type="file"  name="Aadhar Doc"
                                onChange={(e)=>singleFileUpload('aadharBackDoc', e.target.files[0])}/>
                            </div>
                            
                            <div className='apply__card__form__container__field apply__card__form__container__field__file' >
                                <label>Upload GST Certificate {(prev && prev['GSTDoc']) && <>✅</>} </label>
                                <input type="file"  name="GST Certificate"
                                onChange={(e)=>singleFileUpload('GSTDoc', e.target.files[0])}/>
                                
                            </div>
                            
                            <div className='apply__card__form__container__field apply__card__form__container__field__file' >
                                <label>Upload Bank Statement {(prev && prev['bankDoc']) && <>✅</>}  </label>
                                <input type="file"  name="Bank Doc"
                                onChange={(e)=>singleFileUpload('bankDoc', e.target.files[0])}/>
                            </div>
                        
                            <div className='apply__card__form__container__field apply__card__form__container__field__file' >
                                <label>Take a Store Photo {(prev && prev['storePic']) && <>✅</>}  </label>
                                <input type="file"  name="Store Photo" accept="image/*" capture="camera"
                                onChange={(e)=>singleFileUpload('storePic', e.target.files[0])}/>
                            </div>
                            
                            <div className='apply__card__form__container__field apply__card__form__container__field__file' >
                                <label>Take a Selfie {(prev && prev['selfie']) && <>✅</>}  </label>
                                <input type="file"  name="Store Photo" accept="image/*" capture="camera"
                                onChange={(e)=>singleFileUpload('selfie', e.target.files[0])}/>
                            </div>
                            
                            <div className='apply__card__form__container__field apply__card__form__container__field__file' >
                                <label>Upload Cheque {(prev && prev['cheque']) && <>✅</>}  </label>
                                <input type="file"  name="Cheque" capture="camera"
                                onChange={(e)=>singleFileUpload('cheque', e.target.files[0])}/>
                            </div>

                            <div className='apply__card__form__container__field' >
                                <label>Allow us access to your current Location {(prev && prev['loaction']) && <>✅</>}  </label>
                                { !latitude && <button type="button" onClick={()=>getLocation()} >Access my GPS</button> }
                                {
                                    latitude && <p>Latitude - { latitude }</p>
                                }{
                                    longitude && <p>Longitude - { longitude }</p>
                                }
                                {/* <button type="button" onClick={()=>getLocation()} >Access my GPS</button> */}
                            </div>
                        </div>
                        <button className='btn btn__yellow' type='submit'> Submit</button>
                    </form>
                )
            }
            {
                index === 1 &&
                (<div className='apply__card__form__container__success'>
                    <h1>Thank You, for sharing the details</h1>
                    <h2>Your digital onboarding is complete</h2>
                    <p>Your are <b>eligble</b> for</p>
                    <p style={{ textAlign: 'left' }} >
                        <ul>
                            <li>✅ Get attractive <b>discounts</b> on your current <b>invoice</b>.</li>
                            <li>✅ Place <b>orders online</b>.</li>
                            <li>✅ Activate your <b>Pay Later</b> option on all your invoices.</li>
                            <li>✅ Many more <b>benefits</b>...</li>
                        </ul>
                    </p>
                    <p>
                        For more details Contact us <a href="tel:+916360155159" >+916360155159</a>
                    </p>
                    <a href="tel:+916360155159" target="__blank" style={{ zIndex: 10000 }} >
                        <button type='button' className='btn btn__yellow' 
                            style={{ zIndex: 1000 }} >Call Now</button>
                    </a>
                    <p className='apply__card__form__container__success__terms' >*T&C apply</p>
                    <img src="/assets/1.gif" alt="" className='apply__card__form__container__success__gif--left' />
                    <img src="/assets/2.gif" alt="" className='apply__card__form__container__success__img--left' />
                    <img src="/assets/3.gif" alt="" className='apply__card__form__container__success__img--right'/>
                    <img src="/assets/4.gif" alt="" className='apply__card__form__container__success__gif--right'/>
                </div>)
            }
            </div>
        </div>
    )
};