import { useEffect, useState } from "react"
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { finzyCheckStatus, finzyEnach, finzyEsign, finzyVerifyEsign } from "../../api"
import toastService from "../../toast"

export default function Finzy({ setLoading }) {
    const [searchParams] = useSearchParams() 
    const params = useParams()
    const navigate = useNavigate()

    const [status, setStatus] = useState({
        esign: false,
        enach: false,
        paylater: false
    })
    const [error, setError] = useState({ esign: false, enach: false })

    const checkStatus = async () => {
        try {
            setLoading(true)
            const response = await finzyCheckStatus()
            setLoading(false)
            if (response && response.data) {
                const data = response.data
                if (data.redirect) window.location.href = data.redirect
                else {
                    setStatus({
                        enach: data.enach,
                        esign: data.esign,
                        paylater: data.paylater
                    })
                }
            }else {
                toastService.error()
            }
        } catch (error) {
            setLoading(false)
            toastService.error()
        }
    }

    const verifyEsign = async(_data) => {
        try {
            setLoading(true)
            const response = await finzyVerifyEsign(_data, params.key)
            setLoading(false)
            if (response && response.data) {
                if (response.data.status === 'success') {
                    toastService.success('Esign Successful')
                    checkStatus()
                    navigate(`/loan/finzy/${params.key}`)
                } else {
                    toastService.error('Esign failed, Try again')
                    setError({ enach: false, esign: true })
                }
            }
        } catch (error) {
            setLoading(false)
            toastService.error()
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        checkStatus()

        if (searchParams.get('digio_doc_id')) {
            verifyEsign({
                status: searchParams.get('status'),
                documentId: searchParams.get('digio_doc_id'),
                message: searchParams.get('message')
            })
        }

        if (searchParams.get('enach')) {
            if (searchParams.get('enach') === 'success') {
                toastService.success('Enach Successful')
            } else {
                toastService.error('Enach Failed, Try again')
                setError({ enach: true, esign: false })
            }
        }
    }, [])

    const esignClick = async() => {
        try {
            if (status.esign) return;
            setLoading(true)
            const response = await finzyEsign(params.key)
            setLoading(false)
            if (response && response.data) {
                toastService.success('Esign Initiated')
                window.location.href = response?.data?.redirect
            } else {
                toastService.error()
            }
        } catch (error) {
            setLoading(false)
            toastService.error()
        }
    }

    const enachClick = async () => {
        try {
            if (!status.esign) {
                toastService.info('Complete Esign to start Enach')
                return;
            }
            if (status.enach) return;
            setLoading(true)
            const response = await finzyEnach(params.key)
            setLoading(false)
            if (response && response.data) {
                window.location.href = response.data.redirect
            } else {
                toastService.error()
            }
        } catch (error) {
            toastService.error()
        }
    }

    const getClassName = (status, error) => {
        if (error) return 'mandate__container__card__error'
        if (status) return 'mandate__container__card__active'
        else return null
    }

    return(
        <section className="mandate" >
            <div className="mandate__container" >
                <Link to='/loan/invoices' className="mandate__container__back" >
                    <img src="/assets/back.png" alt="back" />
                    Back
                </Link>
                <h4>You are just 2 Step away from Activating Pay Later</h4>
                <div className="mandate__container__card" >
                    <p>Step - 1</p>
                    <button onClick={esignClick}
                        className={getClassName(status.esign, error.esign)} >
                            { !error.esign && ((status.esign)
                                ? 'E- Sign Activated' 
                                : 'Activate E- Sign')
                            }
                            { error.esign &&
                                <>
                                    E- Sign Activation Failed' 
                                    <p>Please try again</p>
                                </> 
                                }
                    </button>
                </div>
                <div className="mandate__container__card" style={{ opacity: (!status.esign) ? '0.5' : '1' }}  >
                    <p>Step - 2</p>
                    <button onClick={enachClick} className={getClassName(status.enach, error.enach)} >
                    { !error.enach && ((status.enach)
                                ? 'E- Nach Activated' 
                                : 'Activate E- Nach')
                            }
                            { error.enach &&
                                <>
                                    E- Nach Activation Failed' 
                                    <p>Please try again</p>
                                </> 
                                }
                    </button>
                </div>
                <Link to={`/loan/invoices/${params.key}`} className="mandate__container__btn btn btn__yellow" 
                    style={{ opacity: (status.paylater) ? '1' : '0.5' }} >
                    Proceed to Pay Later
                </Link>
            </div>
        </section>
    )
}