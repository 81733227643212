import API from "./axiosConfig";
import CONSTANTS from "../constants";
import { getSession } from '../store';

const requestOTP = (_data) => {
    return API.post('/retailer/otp', _data);
};

const verifyOTP = (_data) => {
    return API.post('/retailer/verify-otp', _data);
};

const updateData = (_id, _data) => {
    return API.post(`/retailer/update/${_id}`, _data, {
        headers: {
            Authorization: getSession(CONSTANTS.authCookie)
        }
    });
};

const findData = (_id) => {
    return API.get(`/retailer/details/${_id}`, {
        headers: {
            Authorization: getSession(CONSTANTS.authCookie)
        }
    });
};

const profileData = () => {
    return API.get('/retailer/profile', {
        headers: {
            Authorization: getSession(CONSTANTS.authCookie)
        }
    })
};

const invoiceList = () => {
    return API.get('/retailer/invoices', {
        headers: {
            Authorization: getSession(CONSTANTS.authCookie)
        }
    })
};

const getInvoiceAmount = (_id) => {
    return API.get(`/retailer/invoice/amount/${_id}`, {
        headers: {
            Authorization: getSession(CONSTANTS.authCookie)
        }
    })
}

const getRepayments = () => {
    return API.get('/retailer/repayment', {
        headers: {
            Authorization: getSession(CONSTANTS.authCookie)
        }
    })
}

const singleFile = (_id, _data) => {
    return API.post(`/retailer/upload/file/${_id}`, _data, {
        headers: {
            Authorization: getSession(CONSTANTS.authCookie)
        }
    })
}

const autoLogin = (_id) => {
    return API.get(`/retailer/auto-login/${_id}`)
}

const activatePaylater = () => {
    return API.get('/retailer/activate',{
        headers: {
            Authorization: getSession(CONSTANTS.authCookie)
        }
    })
}

const finzyCheckStatus = () => {
    return API.get('/retailer/finzy/check-status', {
        headers: {
            Authorization: getSession(CONSTANTS.authCookie)
        }
    })
}

const finzyEsign = (_key) => {
    return API.get(`/retailer/finzy/esign?key=${_key}`, {
        headers: {
            Authorization: getSession(CONSTANTS.authCookie)
        }
    })
}

const finzyVerifyEsign = (_data, _key) => {
    return API.post(`/retailer/finzy/esign?key=${_key}`, _data, {
        headers: {
            Authorization: getSession(CONSTANTS.authCookie)
        }
    })
}

const finzyEnach = (_key) => {
    return API.get(`/retailer/finzy/enach/init?key=${_key}`, {
        headers: {
            Authorization: getSession(CONSTANTS.authCookie)
        }
    })
}

export {
    requestOTP,
    verifyOTP,
    updateData,
    findData,
    profileData,
    invoiceList,
    getInvoiceAmount,
    getRepayments,
    singleFile,
    autoLogin,
    activatePaylater,

    finzyCheckStatus,
    finzyEsign,
    finzyVerifyEsign,
    finzyEnach
}