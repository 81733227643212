import { Navigate } from "react-router-dom";

import CONSTANTS from "../constants";
import { getSession } from "../store";

export default function Protected({ children }){
    if(getSession(CONSTANTS.authCookie)){
        return(
            <>
                {children}
            </>
        )
    }else{
        console.log('UNAUTHORIZED')
        return <Navigate to="/"/>
    }

};