import { useEffect, useState } from "react"
import toastService from "../../toast";
import { getRepayments } from "../../api";
import { Link } from "react-router-dom";

export default function Repayment({ setNav, setLoading, setPayLater }){

    const [data,setData] = useState([]);
    
    const loadData = async() => {
        try{
            setLoading(true);
            let response = await getRepayments();
            setLoading(false);
            if(response.data && response.data.status === 'success'){
                setData(response.data.data);
            }else{
                toastService.error();
            }
        }catch(err){
            setLoading(false)
            console.log({ err });
            toastService.error();
        }
    }

    useEffect(()=>{
        setNav(true);
        loadData();
    }, []);

    return(
        <section className="invoices" >
            <h1>REPAYMENT</h1>
            <Link to='/loan' className="invoices__container__back" >
                <img src="/assets/back.png" alt="back" />
                Back
            </Link>
            <div className="invoices__container" >
                <h5>Current Repayment</h5>
                <table>
                    <tr>
                        <th>Dispursal Date</th>
                        <th>Due Date</th>
                        <th>Amount</th>
                        <th>Action</th>
                    </tr>
                    {
                        data.map((e,i)=>(
                            (e.paid === false) ?
                            (<tr>
                                <td>
                                    {new Date(e.dispursalDate).toLocaleDateString()}
                                </td>
                                <td style={{ color: 'orangered' }} >
                                    <b>
                                        {new Date(e.repaymentDate).toLocaleDateString()}
                                    </b>
                                </td>
                                <td>
                                    ₹{e.amount}
                                </td>
                                <td>
                                    <a href="https://finzy.com/login" target="__blank" className="invoices__container__tbtn"   >
                                        Pay Now
                                    </a>
                                </td>
                            </tr>) 
                            : null
                        ))
                    }
                </table>
            </div>
        </section>
    )
}