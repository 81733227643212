import { useEffect, useState } from "react";
import toastService from "../../toast";
import { getInvoiceAmount } from "../../api";
import { useParams } from "react-router-dom";

export default function Success({ setNav }){
    const params = useParams();
    const [invoiceAmount, setInvoiceAmount] = useState(0);
    const [coins, setCoins] = useState(0);

    const future = new Date();
    future.setDate(future.getDate() + 15);

    const loadData = async() => {
        try{
            let response = await getInvoiceAmount(params.key);
            if(response && response.data.status === 'success'){
                setInvoiceAmount(response.data.data.invoiceAmount);
                setCoins(response.data.data.coins)
            }else{
                toastService.error();
            }
        }catch(err){
            console.log(err)
            toastService.error();
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        setNav(true);
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <section className="success" >
            <h1>Congratulations!</h1>
            <div className="success__container" >
                <img src="/assets/loan-success.png" alt="tick" />
                <p>You now have 15 more days to pay this invoice.</p>
                <p>
                    Your new due date is <b>{future.toLocaleDateString()}</b>
                </p>
                <p className="bold" >Earned - <img src="/assets/loan/quid-coin.png" alt="coins" /> &nbsp;
                    {(invoiceAmount ).toLocaleString('en-IN')} QuID coins
                </p>
                <p className="bold" >Total - <img src="/assets/loan/quid-coin.png" alt="coins" /> &nbsp;
                    {(invoiceAmount + coins).toLocaleString('en-IN')} QuID coins</p>
            </div>
        </section>
    )
}

  

