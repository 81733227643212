import LoadingSpin from "react-loading-spin";

export default function Loading({ loading }){
    if(!loading) return null;
    return(
        <div className="modal2">
            <div className="modal2__content">
                <LoadingSpin primaryColor={'#00A897'} />
            </div>
        </div>
    )
};