import { useEffect, useState } from "react"
import toastService from "../../toast";
import { invoiceList } from "../../api";
import { Link } from "react-router-dom";

export default function Invoices({ setNav, setLoading, setPayLater }){

    const [data,setData] = useState([]);
    const [finzy, setFinzy] = useState(false)

    const loadData = async() => {
        try{
            setLoading(true);
            let response = await invoiceList();
            setLoading(false);
            if(response.data && response.data.status === 'success'){
                setData(response.data.data);
                setFinzy(response.data?.paylater || false)
            }else{
                toastService.error();
            }
        }catch(err){
            setLoading(false)
            console.log({ err });
            toastService.error();
        }
    }

    useEffect(()=>{
        setNav(true);
        loadData();
    }, []);

    return(
        <section className="invoices" >
            <h1>INVOICES</h1>
            <Link to='/loan' className="invoices__container__back" >
                <img src="/assets/back.png" alt="back" />
                Back
            </Link>
            <div className="invoices__container" >
                <h5>Current Invoice</h5>
                <table>
                    <tbody>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Brand</th>
                            <th>Action</th>
                        </tr>
                        {
                            data.map((e,i)=>(
                                (i === 0) ?
                                (<tr key={e._id} >
                                    <td>
                                        <Link to={(!finzy) 
                                            ? `/loan/finzy/${e.fileKey}`
                                            : `/loan/paylater/${e.fileKey}`} >
                                            {new Date(e.createdOn).toLocaleDateString()}
                                        </Link>
                                    </td>
                                    <td>
                                        ₹{e.invoiceAmount}
                                    </td>
                                    <td>
                                        {e.brand}
                                    </td>
                                    <td>
                                        <Link className="invoices__container__tbtn" onClick={()=>setPayLater(true)} 
                                            to={(!finzy) 
                                                ? `/loan/finzy/${e.fileKey}`
                                                : `/loan/paylater/${e.fileKey}`}  >
                                            Pay Later
                                        </Link>
                                    </td>
                                </tr>) 
                                : null
                            ))
                        }
                    </tbody>
                </table>
                {
                    data.length > 1 &&
                (<>
                <div className="invoices__container__divider" ></div>
                <h5>Previous Invoices</h5>
                <table>
                    <tbody>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Brand</th>
                            <th>Action</th>
                        </tr>
                        {
                            data.map((e,i)=>(
                                (i !== 0) ?
                                (<tr key={e._id} >
                                    <td>
                                        <Link to={`/loan/invoices/${e.fileKey}`} onClick={()=>setPayLater(false)} >
                                            {new Date(e.createdOn).toLocaleDateString()}
                                        </Link>
                                    </td>
                                    <td>
                                        ₹{e.invoiceAmount}
                                    </td>
                                    <td>
                                        {e.brand}
                                    </td>
                                    <td>
                                        <Link to={`/loan/invoices/${e.fileKey}`} onClick={()=>setPayLater(false)}  >
                                            View Details
                                        </Link>
                                    </td>
                                </tr>) 
                                : null
                            ))
                        }
                    </tbody>
                </table>
                </>)
                }
            </div>
        </section>
    )
}