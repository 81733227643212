import { toast } from "react-toastify";

const success = (_msg) => {
    return toast.success(_msg);
};

const error = (_msg = 'Something went wrong!') =>{
    return toast.error(_msg);
}

const info = (_msg) =>{
    return toast.info(_msg);
}

const toastService = {
    success,
    error,
    info
};

export default toastService;