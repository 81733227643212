function Navbar({ nav }){
    const url = window.location.href
    if(!nav) return null;
    return(
        <div className='nav'>
            <div className='nav__logo'>
                <p className="notranslate" >Cauvery Associates</p>
            </div>
            {
                url.includes('/activate') &&
                <div id="google_translate_element"></div>
            }
        </div>
    )
}

export default Navbar;