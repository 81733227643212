import { useEffect, useState } from "react"
import toastService from "../../toast";
import { profileData } from "../../api";
import { Link } from "react-router-dom";

export default function Profile({ setNav, setLoading }){
    const [data,setData] = useState({ 
        name: '', 
        storeName: '', 
        PAN: '', 
        GST: '', 
        aadhar: '',
        mobile: '',
        otpVerified: null,
    });
    
    const loadData = async() => {
        try{
            setLoading(true);
            let response = await profileData();
            setLoading(false);
            if(response.data && response.data.status === 'success'){
                console.log(response.data.data)
                setData(response.data.data)
            }else{
                toastService.error();
            }
        }catch(err){
            setLoading(false);
            toastService.error();
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        setNav(true);
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setNav]);

    return(
        <section className="profile" >
            <h1>Profile</h1>
            <div className="profile__container" >
                <div className="profile__container__content" >
                    <p>Name: </p>
                    <p>{data.name}</p>
                </div>
                <div className="profile__container__content" >
                    <p>Mobile:</p>
                    <p>{data.mobile} {(data.otpVerified) ? '✅' : '🔴'}</p>
                </div>
                <div className="profile__container__content" >
                    <p>Store Name: </p>
                    <p>{data.storeName}</p>
                </div>
                <div className="profile__container__content" >
                    <p>GST: </p>
                    <p>{data.GST}</p>
                </div>
                <div className="profile__container__content" >
                    <p>PAN: </p>
                    <p>{data.PAN}</p>
                </div>
                <div className="profile__container__content" >
                    <p>Aadhar: </p>
                    <p>{data.aadhar}</p>
                </div>
                <div className="profile__container__btn" >
                    <Link to='/loan' className='btn btn__yellow' >Home</Link>
                </div>
            </div>
        </section>
    )
}