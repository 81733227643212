import Cookies from 'js-cookie'

const setCookie = (_key, _value) => {
    return Cookies.set(_key, _value, { expires: 10 })
};

const getCookie = (_key) => {
    return Cookies.get(_key);
};

const removeCookie = (_key) => {
    return Cookies.remove(_key);
}

const setSession = (_key, _value) => {
    return window.sessionStorage.setItem(_key, _value);
};

const getSession = (_key) => {
    return window.sessionStorage.getItem(_key);
}

export {
    setCookie, 
    getCookie,
    removeCookie,

    setSession,
    getSession
}