import React, { useEffect } from 'react';

export default function ActivateThankYou ({ setNav }) {
    useEffect(() => {
        setNav(false)
    }, [])
    return (
        <section className='thankyou' >
            <img src='/assets/activate/thank-you.png' alt='thank-you' />
            <h2>Thank You</h2>
            <p>An executive from Cauvery Associates, Mysore will call you shortly.</p>
        </section>
    )
}