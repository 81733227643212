import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { profileData } from "../../api";
import toastService from "../../toast";

export default function Loan({ setNav, setLoading }){

    const [data, setData] = useState({ 
        name: '', 
        mobile: '', 
        storeName: '',  
        GST: '', 
        PAN: '',
        aadhar: '',
        otpVerified: false,
        coins: 0,
        repayments: []
    });

    const loadData = async() => {
        try{
            setLoading(true);
            let response = await profileData();
            setLoading(false);
            if(response.data && response.data.status === 'success'){
                setData({ ...response.data.data })
                console.log(response.data.data)
            }else{
                toastService.error();
            }
        }catch(err){
            setLoading(false);
            toastService.error();
        }
    }
    
    useEffect(()=>{
        window.scrollTo(0,0);
        loadData();
        setNav(false);
    }, []);

    return(
       <section className="loan" >
            <img src="/assets/quid-logo-bg-green.png" alt="QuID" className="loan__logo" />
            <h1>QuID Cash</h1>
            <div className="loan__container" >
                <h2>Welcome to Cauvery Associates</h2>
                {/* <img src="/assets/loan/welcome.png" alt="welcome" /> */}
                    <div className="loan__container__content" >
                        <p>Name: </p>
                        <p>{data.name}</p>
                    </div>
                    <div className="loan__container__content" >
                        <p>Mobile:</p>
                        <p>{(data.otpVerified) ? '✅' : '🔴'} {data.mobile}</p>
                    </div>
                    <div className="loan__container__content" >
                        <p>Store Name: </p>
                        <p>{data.storeName}</p>
                    </div>
                    <div className="loan__container__content" >
                        <p>GST: </p>
                        <p>{data.GST}</p>
                    </div>
                    <div className="loan__container__content" >
                        <p>QuID Coins: </p>
                        <p><img src="/assets/loan/quid-coin.png" alt="coins" /> {data.coins}</p>
                    </div>
                <div className="loan__container__btns" >
                    <Link to='/loan/invoices' className='btn btn__yellow' >Invoices</Link>
                    {
                        (data.repayments && data.repayments.length) > 0 &&
                        <Link to='/loan/repayment' className='btn btn__yellow' >Repayment</Link>
                    }
                </div>
            </div>
       </section> 
    )
}