import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { profileData } from "../../api";
import toastService from "../../toast";

export default function Activate({ setNav, setLoading }){

    const [data, setData] = useState({ 
        name: '', 
        mobile: '', 
        storeName: '',  
        GST: '', 
        PAN: '',
        aadhar: '',
        otpVerified: false,
        coins: 0,
        repayments: []
    });

    const loadData = async() => {
        try{
            setLoading(true);
            let response = await profileData();
            setLoading(false);
            if(response.data && response.data.status === 'success'){
                setData({ ...response.data.data })
                console.log(response.data.data)
            }else{
                toastService.error();
            }
        }catch(err){
            setLoading(false);
            toastService.error();
        }
    }
    
    useEffect(()=>{
        window.scrollTo(0,0);
        loadData();
    }, []);

    return(
       <section className="loan" >
            <div className="loan__container" >
                <h2 className="notranslate" >Welcome to Cauvery Associates, Mysore</h2>
                {/* <img src="/assets/loan/welcome.png" alt="welcome" /> */}
                    <div className="loan__container__content" >
                        <p>Name: </p>
                        <p>{data.name}</p>
                    </div>
                    <div className="loan__container__content" >
                        <p>Mobile:</p>
                        <p>{(data.otpVerified) ? '✅' : '🔴'} {data.mobile}</p>
                    </div>
                    <div className="loan__container__content" >
                        <p>Store Name: </p>
                        <p>{data.storeName}</p>
                    </div>
                    <div className="loan__container__content" >
                        <p>GST: </p>
                        <p>{data.GST}</p>
                    </div>
                    <div className="loan__container__content" >
                        <p>QuID Coins: </p>
                        <p><img src="/assets/loan/quid-coin.png" alt="coins" /> {data.coins}</p>
                    </div>
                <div className="loan__container__btns" >
                    <Link to='/activate/features' className='btn btn__yellow' >Confirm</Link>
                </div>
            </div>
       </section> 
    )
}