/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useNavigate, useParams } from "react-router-dom"
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { getSession } from "../../store";
import CONSTANTS from "../../constants";
import { useEffect, useState } from "react";
import toastService from "../../toast";
import { activatePaylater, getInvoiceAmount } from "../../api";

pdfjs.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js';

export default function ActivateInvoiceDetails({ setNav, payLater, setLoading }){
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()

    const params = useParams();

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const requestHeaders = {
        Authorization: getSession(CONSTANTS.authCookie)
    };

    const onNextClick = () => {
        if(currentPage < numPages){
            setCurrentPage( state=>state + 1 )
        }
    };

    const onPreviousClick = () => {
        if(currentPage > 1){
            setCurrentPage( state => state - 1 )
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        setNav(true);
    }, [])

    const onPayLater = async () => {
        try {
            setLoading(true)
            const response = await activatePaylater()
            setLoading(false)
            if (response && response.data) {
                navigate('/activate/thank-you')
            }
        } catch (error) {
            setLoading(false)
            toastService.error()
        }
    }

    return(
        <section className="invoice" >
            <div className="invoice__container" >
                <Link to='/activate/invoices' className="invoice__container__back" >
                    <img src="/assets/back.png" alt="back" />
                    Back
                </Link>
                <div className="invoice__container__file" >
                    <Document 
                        className={"PDFDocument"} 
                        file={{
                            url: CONSTANTS.apiUrl + '/retailer/invoice/detail/' + params.key,
                            httpHeaders: requestHeaders,
                        }} 
                        onLoadSuccess={onDocumentLoadSuccess}>  
                        <Page
                            className={"PDFPage PDFPageOne"} 
                            renderTextLayer={false} renderInteractiveForms={false}
                            key={`page`} pageNumber={currentPage}  />
                    </Document>
                    {
                        (numPages > 1) &&
                        (<div className="page-controls">
                            <button type="button" onClick={onPreviousClick} >{'<'}</button>
                                <span>{currentPage} of {numPages}</span>
                            <button type="button" onClick={onNextClick} >{'>'}</button>
                        </div>)
                    }
                </div>
                <div className="invoice__container__btn" >
                    {
                        payLater && (
                            <button onClick={onPayLater} className='btn btn__yellow' style={{ cursor: 'pointer' }} >Activate Pay Later</button>
                        )
                    }
                </div>
            </div>
        </section>
    )
};