import React,{useState, useEffect} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import toastService from '../../toast';
import { requestOTP, verifyOTP, autoLogin } from '../../api';
import { setSession } from '../../store';
import CONSTANTS from '../../constants';

function Welcome({setNav, setLoading }){
    const [number,setNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [showOTP, setShowOTP] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams() 

    useEffect(() => {
        if (window.document.scrollingElement.hasAttribute("style")) {
          window.document.scrollingElement.setAttribute("style", "");
        }
    });

    const sendAutoLogin = async (_id) => {
        try {
            const response = await autoLogin(_id)
            if (response && response.data) {
                let { otp, token, id, activateFlow = false } = response.data;
                console.log({ data: response.data })
                if (otp) {
                    toastService.success('OTP Sent successfully');
                    setShowOTP(true);
                } else {
                    toastService.success('Login Successfull');
                    await setSession(CONSTANTS.authCookie, token);
                    await setSession(CONSTANTS.user_id, id);
                    if (activateFlow) {
                        navigate('/activate');
                    } else {
                        navigate(`/apply/${id}`);
                    }
                }
            }
            console.log({ response })
        } catch (error) {
            toastService.error()
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        setNav(false);
        if (searchParams.get('_id') && searchParams.get('activate') === 'true') {
            sendAutoLogin(searchParams.get('_id'))
        }
    }, []);

    const onSubmit = async() => {
        try{
            // e.preventDefault();
            console.log('SEND OTP')
            if(number.length !== 10){
                toastService.error('Enter valid 10 digit number');
                return;
            }
            setLoading(true);
            let response = await requestOTP({ mobile: number, distributor: CONSTANTS.distributor });
            setLoading(false);
            if(response && response.status === 200){
                let { otp, token, id, invoice_flow = false } = response.data;
                if(otp){
                    toastService.success('OTP Sent successfully');
                    setShowOTP(true);
                }else{
                    toastService.success('Login Successfull');
                    await setSession(CONSTANTS.authCookie, token);
                    await setSession(CONSTANTS.user_id, id);
                    if(invoice_flow){
                        navigate('/loan');
                    }else{
                        navigate(`/apply/${id}`);
                    }
                }
            }else{
                toastService.error();
            }
        }catch(err){
            setLoading(false);
            toastService.error();
        }
    };

    const verifyOTPCheck = async() => {
        try{
            if(otp.length !== 4){
                toastService.error('Invalid OTP, Try again');
                return;
            }

            setLoading(true);
            let response = await verifyOTP({ mobile: number, otp });
            setLoading(false);
            if(response && response.status === 200){
                toastService.success('OTP verified success fully');
                let data = response.data;
                await setSession(CONSTANTS.authCookie, data.token);
                navigate(`/apply/${data.id}`);
                
            }
        }catch(err){
            setLoading(false);
            console.log({ err });
            if(`${err.message}`.includes('401')){
                toastService.error('Check OTP and Try Again');
                return;
            }
            toastService.error();
        }
    }

    return (
        <div className="welcome">
            {/* <img src="https://www.ripplr.in/src/image/home.png" alt="logo" /> */}
            <h2 >Welcome to Cauvery Associates</h2>
                <form className='welcome__form' >
                    <div className='welcome__form__number' >
                        <span>+91</span>
                        <input type='tel' name='number' 
                        value={number} 
                        onChange={(e)=>setNumber(e.target.value)} placeholder='ex. 9445678904' />
                    </div>
                    {
                        showOTP &&
                    (<>
                        <h3> Enter OTP </h3>
                        <div className='welcome__form__number' >
                            <input type='number' name='otp' 
                                value={otp} 
                                style={{ marginLeft: 0, outline: 'none' }}
                                onChange={(e)=>setOtp(e.target.value)} placeholder='ex. 4567' />
                        </div>
                    </>
                    )
                    }
                </form>
                {
                    (!showOTP) &&
                    <button className='btn btn__yellow' onClick={()=>onSubmit()} >Proceed</button>
                }
                {
                    (showOTP) &&
                    <button className='btn btn__yellow' onClick={()=>verifyOTPCheck()} >Verify OTP</button>
                }
        </div>
    );
}

export default Welcome;
