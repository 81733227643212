import axios from 'axios';
import CONSTANTS from '../constants';
import { getCookie } from '../store';

const API = axios.create({
    baseURL: CONSTANTS.apiUrl,
    headers: {
        Authorization: getCookie(CONSTANTS.authCookie) || '' 
    }
});

export default API;